import { NavigationGuard, RouteConfig } from "vue-router";
import store from "@/store";
import { checkPermission } from "./adminPermission";

const authGuard: NavigationGuard<Vue> = (to, from, next) => {
  // next();
  store.dispatch("checkAdminAuth").then(() => {
    const isAuthen = store.getters.isAdminAuthen;
    if (isAuthen) {
      next();
    } else {
      window.location.assign(process.env.VUE_APP_SSO_PATH);
    }
  });
};

const permissionGuard: NavigationGuard<Vue> = (to, from, next) => {
  const permission = store.getters.adminPermission;
  if (checkPermission(to.path, permission)) {
    next();
  } else {
    next(from.path);
  }
};

const ssoGuard: NavigationGuard<Vue> = async (to, from, next) => {
  const token = to.query.token as string;
  if (token) {
    console.log('token>>>',token);
    
    localStorage.setItem(process.env.VUE_APP_ADMIN_KEY, token);
    localStorage.setItem(process.env.VUE_APP_APP_KEY, token);

    console.log("Before dispatch checkAdminAuth");
store.dispatch("checkAdminAuth").then(() => {
  console.log("checkAdminAuth success");
  next("/admin");
}).catch(error => {
  console.error("checkAdminAuth failed:", error);
  next(false); // หยุดการนำทางในกรณีเกิดข้อผิดพลาด
});
  } else {
    window.location.assign(process.env.VUE_APP_SSO_PATH);
  }
};

export const adminRoutes: Array<RouteConfig> = [
         {
           path: "/admin/sso",
           name: "adminsso",
           beforeEnter: ssoGuard
         },
         // {
         //   path: "/admin/login",
         //   name: "AdminLogin",
         //   beforeEnter: authGuard,
         //   component: Login
         // },
         {
           path: "/admin",
           name: "admin",
           redirect: "/admin/academy",
           beforeEnter: authGuard,
           component: () => import("../views/Admin/index.vue"),
           children: [
             {
               path: "dashboard",
               name: "dashboard",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/dashboard/index.vue")
             },
             {
               path: "academy",
               beforeEnter: permissionGuard,
               name: "Academy",
               component: () => import("../views/Admin/academy/index.vue")
             },
             {
               path: "academy/:level/:id",
               beforeEnter: permissionGuard,
               name: "approveLicense",
               component: () =>
                 import("../views/Admin/academy/ApproveLicense/index.vue"),
               children: [
                 {
                   path: "",
                   name: "Overview",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/Overview.vue"
                     )
                 },
                 {
                   path: "leadership",
                   name: "Leadership",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/leadership/index.vue"
                     )
                 },
                 {
                   path: "planning",
                   name: "Planning",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/planning/index.vue"
                     )
                 },
                 {
                   path: "organization",
                   name: "Organization",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/organization/index.vue"
                     )
                 },
                 {
                   path: "staffing",
                   name: "Staffing",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/staffing/index.vue"
                     )
                 },
                 {
                   path: "finance",
                   name: "Finance",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/finance/index.vue"
                     )
                 },
                 {
                   path: "facilities",
                   name: "Facilities",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/facilities/index.vue"
                     )
                 },
                 {
                   path: "coaching",
                   name: "Coaching",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/coaching/index.vue"
                     )
                 },
                 {
                   path: "playing",
                   name: "Playing",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/playing/index.vue"
                     )
                 },
                 {
                   path: "health",
                   name: "Health",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/health/index.vue"
                     )
                 },
                 {
                   path: "fitness",
                   name: "Fitness",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/fitness/index.vue"
                     )
                 },
                 {
                   path: "education",
                   name: "Education",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/education/index.vue"
                     )
                 },
                 {
                   path: "collaboration",
                   name: "Collaboration",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/collaboration/index.vue"
                     )
                 },
                 {
                   path: "assessment",
                   name: "Assessment",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/assessment/index.vue"
                     )
                 },
                 {
                   path: "rules",
                   name: "Rules",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/rule/index.vue"
                     )
                 },
                 {
                   path: "results",
                   name: "Results",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/result/index.vue"
                     )
                 },
                 {
                   path: "recruitment",
                   name: "Recruitment",
                   component: () =>
                     import(
                       "../views/Admin/academy/ApproveLicense/recruitment/index.vue"
                     )
                 },
                 {
                   path: "player_performance",
                   name: "player performance",
                   component: () =>
                     import(
                       "@/views/Admin/academy/ApproveLicense/performance/index.vue"
                     )
                 },
                 {
                   path: "psychology",
                   name: "Psychology",
                   component: () =>
                     import(
                       "@/views/Admin/academy/ApproveLicense/psychology/index.vue"
                     )
                 },
                 {
                   path: "welfare",
                   name: "welfare",
                   component: () =>
                     import(
                       "@/views/Admin/academy/ApproveLicense/welfare/index.vue"
                     )
                 },
                 {
                   path: "teams",
                   name: "Teams",
                   component: () =>
                     import(
                       "@/views/Admin/academy/ApproveLicense/teams/index.vue"
                     )
                 }
               ]
             },
             {
               path: "activity",
               name: "activity",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/activity/index.vue")
             },
             // {
             //   path: "activity/:type",
             //   name: "singleActivity",
             //   beforeEnter: permissionGuard,
             //   component: () => import("../views/Admin/activity/single/index.vue")
             // },
             {
               path: "user",
               name: "user",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/user/index.vue")
             },
             {
               path: "period",
               name: "period",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/period/index.vue")
             },
             {
               path: "level",
               name: "level",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/level/index.vue")
             },
             {
               path: "level/:level",
               name: "ChangeCertificate",
               component: () => import("../views/Admin/level/changeText.vue")
             },
             {
               path: "category",
               beforeEnter: permissionGuard,
               component: () => import("../views/Admin/category/index.vue"),
               children: [
                 {
                   path: "",
                   name: "CategoryOverview",
                   component: () => import("@/views/Admin/category/Table.vue")
                 },
                 {
                   path: "leadership",
                   name: "Leadership",
                   component: () =>
                     import("@/views/Admin/category/leadership/index.vue")
                 },
                 {
                   path: "planning",
                   name: "Planning",
                   component: () =>
                     import("@/views/Admin/category/planning/index.vue")
                 },
                 {
                   path: "organization",
                   name: "Organization",
                   component: () =>
                     import("@/views/Admin/category/organization/index.vue")
                 },
                 {
                   path: "staffing",
                   name: "Staffing",
                   component: () =>
                     import("@/views/Admin/category/staffing/index.vue")
                 },
                 {
                   path: "finance",
                   name: "Finance",
                   component: () =>
                     import("@/views/Admin/category/finance/index.vue")
                 },
                 {
                   path: "facilities",
                   name: "Facilities",
                   component: () =>
                     import("@/views/Admin/category/facilities/index.vue")
                 },
                 {
                   path: "coaching",
                   name: "Coaching",
                   component: () =>
                     import("@/views/Admin/category/coaching/index.vue")
                 },
                 {
                   path: "playing",
                   name: "Playing",
                   component: () =>
                     import("@/views/Admin/category/playing/index.vue")
                 },
                 {
                   path: "health",
                   name: "Health",
                   component: () =>
                     import("@/views/Admin/category/health/index.vue")
                 },
                 {
                   path: "fitness",
                   name: "Fitness",
                   component: () =>
                     import("@/views/Admin/category/fitness/index.vue")
                 },
                 {
                   path: "education",
                   name: "Education",
                   component: () =>
                     import("@/views/Admin/category/education/index.vue")
                 },
                 {
                   path: "collaboration",
                   name: "Collaboration",
                   component: () =>
                     import("@/views/Admin/category/collaboration/index.vue")
                 },
                 {
                   path: "assessment",
                   name: "Assessment",
                   component: () =>
                     import("@/views/Admin/category/assessment/index.vue")
                 },
                 {
                   path: "rules",
                   name: "Rules",
                   component: () =>
                     import("@/views/Admin/category/rules/index.vue")
                 },
                 {
                   path: "results",
                   name: "Results",
                   component: () =>
                     import("@/views/Admin/category/result/index.vue")
                 },
                 {
                   path: "recruitment",
                   name: "Recruitment",
                   component: () =>
                     import("@/views/Admin/category/recruitment/index.vue")
                 },
                 {
                   path: "player_performance",
                   name: "player performance",
                   component: () =>
                     import("@/views/Admin/category/performance/index.vue")
                 },
                 {
                   path: "psychology",
                   name: "Psychology",
                   component: () =>
                     import("@/views/Admin/category/psychology/index.vue")
                 },
                 {
                   path: "welfare",
                   name: "welfare",
                   component: () =>
                     import("@/views/Admin/category/welfare/index.vue")
                 },
                 {
                   path: "teams",
                   name: "Teams",
                   component: () =>
                     import("@/views/Admin/category/teams/index.vue")
                 }
               ]
             }
           ]
         }
       ];
